import React from "react";
import Head from "next/head";
import { scriptUrl } from "../constants";



function App({ Component, pageProps }) {
  return (
    <>
      <Head>
          <meta name="viewport" content="width=device-width, initial-scale=1.0"/>
          <link rel="preconnect" href={scriptUrl} />
      </Head>
      <Component {...pageProps} />
    </>
  );
}

export default App;
